import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from './domov'


const IndexPage = () => (
  <Layout>
    <SEO title="Zubná ambulancia"/>
    <Home/>
  </Layout>
)

export default IndexPage
